@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    scroll-behavior: smooth;
    list-style: none;
    font-family: 'Chivo Mono', monospace;
}

:root{
    --green-color: #51ff00;
    --white-color: #fff;
    --grey-color: #535353;
    --first-color: #1c1c1c;
    --second-color: #262626;
    --third-color: #303030;
}
html{
    overflow: hidden;
    width: 100%;
    background: linear-gradient(135deg, var(--first-color) 0%, var(--third-color) 100%);
}
body{
    width: 100%;
    height: 100vh;
    margin: 0;
}

.main-page{
    opacity: 0;
}

.progress-bar{
    box-shadow: none !important;
    position: fixed;
    width: 100%;
}

.progress-bar .progress-value{
    opacity: 1;
    width: 0%;
    height: 4px !important;
}

/*
 *  Header
 */
.header{
    display: flex;
    position: fixed;
    width: 4%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1.4rem 0;
}
.navbar{
    display: flex;
    height: inherit;
    justify-content: space-evenly;
    writing-mode: vertical-rl;
    transform: rotateZ(180deg);
}
.navbar a{
    font-size: 1.5rem;
    color: var(--green-color);
    text-transform: uppercase;
    letter-spacing: 5px;
    text-shadow: var(--green-color) 1px 0px 15px;
}
.navbar a:hover{
    color: var(--white-color);
    text-shadow: var(--white-color) 1px 0px 15px;
}
.navbar li:nth-child(2) a{
    margin: 0 30px;
}

/*
 * Pages
 */
.container{
    position: absolute;
    width: 100%;
    height: 100%;
    /* scroll-snap-type: x mandatory; */
    overflow-x: scroll;
    /* scroll-behavior: smooth; */
    display: flex;
}
section{
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
}

/* Home */
section:nth-of-type(1){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    color: var(--green-color);
    align-items: center;
}
.homeText{
    /* border: solid 1px red; */
    padding: 0 0 0 20%;
}
.homeText h4{
    font-size: 1.2rem;
    font-weight: 100;
    color: #d1d1d1;
}
.homeText h1{
    font-size: 5rem;
    color: rgb(97, 97, 97);
}
span{
    color: var(--green-color);
}
.homeText h3{
    font-size: 1.4rem;
    font-weight: 300;
    color: rgb(109, 109, 109);
}
.btn{
    border: solid 2px #51ff00;
    color: var(--white-color);
    padding: 10px;
    display: block;
    width: fit-content;
    margin-top: 10px;
    border-radius: 10px;
    /* transition: all 5s; */
    box-shadow: 0 0 10px var(--green-color);
}
.btn:hover{
    border: solid 2px var(--white-color);
    box-shadow: 0 0 10px var(--white-color);
}

/* Info */
section:nth-of-type(2){
/*     border: solid 1px red; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    color: var(--white-color);
}

.skills{
    /* border: solid 1px red; */
    color: var(--white-color);
    width: 60%;
    overflow-y: scroll;
    padding: 15px;
    max-height: 80%;
    margin: 0 auto;
}
.formazione::-webkit-scrollbar,
.esperienze::-webkit-scrollbar,
.skills::-webkit-scrollbar{
    display: block !important;
    width: 8px;
}
.formazione::-webkit-scrollbar-thumb,
.esperienze::-webkit-scrollbar-thumb,
.skills::-webkit-scrollbar-thumb{
    background-color: var(--grey-color);
    border-radius: 10px;
}
.formazione::-webkit-scrollbar-thumb:hover,
.esperienze::-webkit-scrollbar-thumb:hover,
.skills::-webkit-scrollbar-thumb:hover{
    background-color: var(--white-color);
}
.info h1{
    text-shadow: var(--white-color) 1px 0px 15px;
}
.skills li{
    margin: 20px 0;
}
.skills h3{
    margin-top: 7px;
}
.bar{
    background: transparent;
    display: block;
    height: 10px;
    border: solid 1px var(-whitecolor);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px var(--white-color);
    padding: 3px;
}
.bar span{
    height: 10px;
    float: left;
    border-radius: 29px;
    background: var(--green-color);
    box-shadow: 0 0 10px var(--green-color);
    opacity: 0;
}
.is-visible span{
    opacity: 1;
}
.is-visible .winServer{
    width: 90%;
    animation: winServer 2s;
}
.is-visible .linux{
    width: 55%;
    animation: linux 2s;
}
.is-visible .scripting{
    width: 75%;
    animation: scripting 2s;
}
.is-visible .networking{
    width: 85%;
    animation: networking 2s;
}
.is-visible .azure{
    width: 65%;
    animation: azure 2s;
}
.is-visible .aws{
    width: 65%;
    animation: aws 2s;
}
.is-visible .photoshop{
    width: 75%;
    animation: photoshop 2s;
}
.is-visible .hardware{
    width: 100%;
    animation: hardware 2s;
}
.is-visible .blender{
    width: 85%;
    animation: blender 2s;
}
@keyframes winServer{
    0% {width: 0%;}
    100% {width: 90%;}
}
@keyframes linux{
    0% {width: 0%;}
    100% {width: 55%;}
}
@keyframes scripting{
    0% {width: 0%;}
    100% {width: 75%;}
}
@keyframes networking{
    0% {width: 0%;}
    100% {width: 85%;}
}
@keyframes azure{
    0% {width: 0%;}
    100% {width: 65%;}
}
@keyframes aws{
    0% {width: 0%;}
    100% {width: 65%;}
}
@keyframes photoshop{
    0% {width: 0%;}
    100% {width: 75%;}
}
@keyframes hardware{
    0% {width: 0%;}
    100% {width: 100%;}
}
@keyframes blender{
    0% {width: 0%;}
    100% {width: 85%;}
}

.formazione,
.esperienze{
    /* border: solid 1px red; */
    margin-right: 30px;
    max-height: 80%;
    overflow-y: scroll;
    padding: 10px;
}
.formazione h2 a,
.esperienze h2 a{
    margin-top: 12px;
    color: var(--green-color);
    text-shadow: var(--green-color) 1px 0px 5px;
}
.formazione h2:hover,
.esperienze h2:hover{
    transition: .3s ease-in-out;
    transform: scale(1.03);
}
.formazione h2:hover *,
.esperienze h2:hover *{
    color: var(--white-color) !important;
    text-shadow: var(--white-color) 1px 0px 5px !important;
}
.formazione h3,
.esperienze h3{
    font-size: 1rem;
    font-weight: 200;
    font-style: italic;
    margin: 6px;
}
.formazione li,
.esperienze li{
    list-style-type: disc;
    margin-left: 20px;
    line-height: 25px;
}
.formazione li b:hover,
.esperienze li b:hover{
    color: var(--green-color);
    text-shadow: var(--green-color) 1px 0px 5px;
    cursor: pointer;
}
.data{
    font-size: 1.2rem;
}

/* Contact */
section:nth-of-type(3){
    color: var(--green-color);
    display: flex;
}
.contact form{
    display: flex;
    flex-direction: column;
    /* border: solid 2px var(--green-color); */
    border-radius: 15px;
    width: 50%;
    margin: 0 auto;
    padding: 10px;
}
form input,
form textarea{
    background: transparent;
    padding: 10px;
    margin: 10px;
    font-size: 1.1rem;
    border: none;
    border-bottom: solid 1px var(--white-color);
    color: var(--white-color);
    resize: none;
}
form .send{
    text-align: center;
    width: 30%;
    margin: auto;
    border: solid 2px var(--white-color);
    border-bottom: solid 2px var(--white-color);
    cursor: pointer;
    transition: all .5s ease;
}
form .send:hover,
form .send:focus{
    background-color: var(--white-color);
    color: var(--first-color);
}
.social{
    text-align: center;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    bottom: 0;
}
.social a{
    font-size: 2.5rem;
    padding: 20px;
    color: var(--green-color);
    transition: all .10s ease;
    text-shadow: var(--green-color) 1px 0px 5px;
}
.social a:hover{
    transform: translateY(-3px);
    color: var(--white-color);
    text-shadow: var(--white-color) 1px 0px 5px;
}

/*
 * Popup Info
 */
 .popup{
    background-color: var(--third-color);
    width: 30%;
    padding: 30px 40px;
    position: fixed;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    border-radius: 8px;
    font-family: "Poppins",sans-serif;
    display: none; 
    text-align: center;
}
.visible{
    display: block;
}
.popup button{
    display: block;
    margin:  0 0 20px auto;
    background-color: transparent;
    font-size: 30px;
    color: var(--second-color);
    background: var(--green-color);
    border-radius: 100%;
    width: 40px;
    height: 40px;
    border: none;
    outline: none;
    cursor: pointer;
}
.popup h2{
  margin-top: -20px;
  color: var(--white-color);
}
.popup ul li,
.popup p{
    color: var(--white-color) !important;
    font-size: 14px;
    text-align: justify;
    margin: 20px 0;
    line-height: 25px;
}
.popup ul li{
    margin-left: 10px;
    text-align: left !important;
    list-style: disc;
}


/*
 *  Canvas
 */
canvas{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}

/* Scroll Bar */
::-webkit-scrollbar{
    display: none;
}

/* Loading Page */
.loading-page{
    position: fixed;
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid var(--green-color);
    border-color: var(--green-color) transparent var(--green-color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/*
 *  Responsive
 */
 @media (min-height: 800px){
    .navbar{
        max-height: 50%;
    }
 }

 @media (max-width: 1000px){
    /* Header */
    .navbar{
        width: 100%;
        height: auto;
        writing-mode:horizontal-tb;
        transform: rotateZ(0deg);
        justify-content: center;
    }

    .header{
        width:100%;
        height:auto;
    }

    /* Info */
    section:nth-of-type(2){
        display: block;
        overflow-y: scroll;
    }

    .skills{
        margin-top: 100px;
        width: 80%;
        max-height: none;
    }

    .formazione,
    .esperienze{
        padding: 10%;
        width: 80%;
        max-height: none;
    }
    .esperienze{
        padding-top: 0;
    }

    .contact form{
        margin: auto;
        width: 80%;
    }

    .popup{
        width: 60%;
    }
 }

